import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Szechenyi2020() {
  return (
    <div>
      <Header />
      <div class="mt-32 mx-16">
        <p class="font-bold">Pályázó neve:</p>
        <p>VASFA Szolnoki Kazángyártó Korlátolt Felelősségű Társaság</p>
        <p class="font-bold">Projekt címe:</p>
        <p>Technológiafejlesztés a Vasfa Kft-nél.</p>
        <p class="font-bold">Szerződött támogatás összege: </p>
        <p>36 751 605 Ft</p>
        <p class="font-bold">Támogatás mértéke:</p>
        <p>70,00 %</p>
        <p class="font-bold">Projekt tartalma:</p>
        <p>
          Cégünk, a VASFA Szolnoki Kazángyártó Kft., ill. jogelődje közel 60 éve
          alapíttatott és több mint negyven éve gyárt kazánokat és kazánházi
          berendezéseket. Ezeknek megfelelően vállalkozásunk főtevékenysége a
          gőzkazán gyártása. <br></br>A cég az energetikai berendezések
          fejlesztése és gyártása területén fokozatosan fejlődött,
          gyártmánystruktúrája mára jelentősen bővült a gyártási feltételekkel
          összefüggő műszaki, technológiai fejlesztések eredményeként. A saját
          tervezésű, elismert minőségű berendezéseink elsősorban a hazai
          energetikai piacon meghatározóak, de az elmúlt években fokozatosan
          bővítettük export tevékenységünket is. A kazánokat, nyomástartó
          berendezéseket az uniós szabványok és előírások szerint tervezzük,
          gyártjuk, vizsgáljuk, tanúsítjuk és CE jellel szállítjuk. Cégünk
          fővállalkozásban vállalja komplett kazánházak létesítését - melybe
          elsősorban termékeinket építjük be - valamint meglévő kazánházakban
          kazáncserék kivitelezését tervezéssel engedélyezéssel, üzembe
          helyezéssel, átadással együtt. <br></br>
          Az ipari gőzkazángyártás rendkívül anyagigényes, az anyagmozgatás
          targoncákkal valósítható meg. A jó minőségű anyagmozgató gépek
          elengedhetetlen részei a termelésnek. 2020. június 1-től szigorú
          környezetvédelmi előírások léptek hatályba a targoncák káros anyag
          kibocsájtását illetően, így csak EURO-V-ös motorral rendelkezőt lehet
          az országba behozni. Ennek megfelelve korszerű targoncák beszerzését
          tervezzük. <br></br>
          Az élhajlító berendezés tovább növeli a termelés hatékonyságát és
          berendezések műszaki színvonalát. Az élhajlító az anyag,
          fémmegmunkálás területén hoz egyrészt megmunkálási tartományváltozást,
          másrészt hatékonyság és termelékenységnövelést. Így egyszerre válik
          lehetővé termékportfólió bővítés és eredményességnövelés cégünknél.
          Mindezek hozzájárulnak munkavállalói létszámunk megőrzéséhez.
        </p>
        <p class="font-bold">Projekt befejezési dátuma:</p>
        <p></p>2021.09.30.
        <p class="font-bold">Projektazonosító száma:</p>
        <p>GINOP-1.2.8-20-2020-02957</p>
      </div>
      <div class="szechenyi-images">
        <img src="/images/szechenyi-1.jpg" alt="Széchényi támogatás: élhajlító"></img>
        <img src="/images/szechenyi-2.jpg" alt="Széchényi támogatás: élhajlító"></img>
        <img src="/images/szechenyi-3.jpg" alt="Széchényi támogatás: élhajlító"></img>
        <img src="/images/szechenyi-4.jpg" alt="Széchényi támogatás: élhajlító"></img>
        <img src="/images/szechenyi-5.jpg" alt="Széchényi támogatás: élhajlító"></img>
        <img src="/images/szechenyi-6.jpg" alt="Széchényi támogatás: élhajlító"></img>
      </div>

      <div class="mt-32 mx-16">
        <p class="font-bold">Pályázó neve:</p>
        <p>VASFA Szolnoki Kazángyártó Korlátolt Felelősségű Társaság</p>
        <p class="font-bold">Projekt címe:</p>
        <p>
          A Vasfa Kft. versenyképességének növelése technológiai innováció
          révén.
        </p>
        <p class="font-bold">Szerződött támogatás összege: </p>
        <p>29 120 400 Ft</p>
        <p class="font-bold">Támogatás mértéke:</p>
        <p>50,00 %</p>
        <p class="font-bold">Projekt tartalma:</p>
        <p>
          Cégünk, a VASFA Szolnoki Kazángyártó Kft., ill. jogelődje közel 60 éve
          alapíttatott és több mint negyven éve gyárt kazánokat és kazánházi
          berendezéseket. Ezeknek megfelelően vállalkozásunk főtevékenysége a
          gőzkazán gyártása. <br></br>A cég az energetikai berendezések
          fejlesztése és gyártása területén fokozatosan és folyamatosan
          fejlődik, gyártmánystruktúrája mára jelentősen bővült a gyártási
          feltételekkel összefüggő műszaki, technológiai fejlesztések
          eredményeként. A saját tervezésű, elismert minőségű berendezéseink
          elsősorban a hazai energetikai piacon meghatározóak, de az elmúlt
          években fokozatosan bővítettük exporttevékenységünket is. A kazánokat,
          nyomástartó berendezéseket az uniós szabványok és előírások szerint
          tervezzük, gyártjuk, vizsgáljuk, tanúsítjuk és CE jellel szállítjuk.
          Cégünk fővállalkozásban vállalja komplett kazánházak létesítését -
          melybe elsősorban termékeinket építjük be -, valamint meglévő
          kazánházakban kazáncserék kivitelezését tervezéssel engedélyezéssel,
          üzembe helyezéssel, átadással együtt.<br></br>
          Az ipari gőzkazángyártás rendkívül anyagigényes, az alapanyag
          darabolásból származtatott kihozatala rendkívül fontos. A
          BPL-H-3006-300-EC-L CNC vezérelt finomsugaras plazmavágó berendezés
          tovább növeli a termelés hatékonyságát és berendezések műszaki
          színvonalát. <br></br>A finomsugaras plazmavágó berendezés az anyag
          darabolás területén hoz egyrészt tartományváltozást, másrészt
          hatékonyság és termelékenységnövelést. Így egyszerre válik lehetővé
          termékportfólió bővítés és eredményességnövelés cégünknél. Mindezek
          hozzájárulnak munkavállalói létszámunk megőrzéséhez.
        </p>
        <p class="font-bold">Projekt fizikai befejezésének határideje:</p>
        <p></p>2019.07.01.
        <p class="font-bold">Projektazonosító száma:</p>
        <p>GINOP-2.1.8-17-2018-02407</p>
      </div>
      <div class="szechenyi-images-2">
        <img src="/images/szechenyi2-1.jpg" alt="Széchényi támogatás: plazmavágó"></img>
        <img src="/images/szechenyi2-2.jpg" alt="Széchényi támogatás: plazmavágó"></img>
        <img src="/images/szechenyi2-3.jpg" alt="Széchényi támogatás: plazmavágó"></img>
      </div>
      <Footer />
    </div>
  );
}
