import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

export default function Gyartas() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="text-4xl mt-4 flex justify-center items-center h-screen">
      {t('under-construction')}
      </div>
      <Footer />
    </div>
  );
}
