import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";
import { Kitchen, LocalDrink } from "@mui/icons-material";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  const popupRef = useRef(null);

  const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
      setCurrentIndex(slideIndex);
    };

    const slides = [
      "/images/vasfa-main-1.png",
      "/images/vasfa-main-2.png",
      "/images/vasfa-main-3.png",
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        nextSlide();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }, [currentIndex, nextSlide]);

    return (
      <div className="mt-32 relative group w-full h-96">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex]})` }}
          className="slide-img-home cursor-pointer"
          onClick={() => {
            setCurrentIndex(currentIndex);
          }}
        ></div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <ImageSlider />

      <div className="home-page-about-container">
        <p className="home-page-about-title">{t("short-description-title")}</p>
        <p className="home-page-about-text">{t("short-description-content")}</p>
        <p>
          <button className="home-page-about-button">
            <a href="/rolunk">{t("get-to-know-more")}</a>
          </button>
        </p>
      </div>

      <span className="technologies-container-title">{t("utilisation")}</span>
      <div class="technologies-container">
        <div className="technologies-container-left">
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-prescription-bottle-medical"></i>
            </span>
            <span>{t("pharmaceutical")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-regular fa-hospital"></i>
            </span>
            <span>{t("healthcare")}</span>
          </div>
          <div className="technologies-container-element">
            <span className="material-icons">
              <Kitchen />
            </span>
            <span>{t("canning")}</span>
          </div>
          <div className="technologies-container-element">
            <span className="material-icons">
              <LocalDrink />
            </span>
            <span>{t("soft-drinks")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-utensils"></i>
            </span>
            <span>{t("food")}</span>
          </div>
        </div>
        <div className="technologies-container-right">
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-car"></i>
            </span>
            <span>{t("vehicle")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-flask"></i>
            </span>
            <span>{t("chemical")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-wrench"></i>
            </span>
            <span>{t("machine")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-dumpster"></i>
            </span>
            <span>{t("waste-processing")}</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-lightbulb"></i>
            </span>
            <span>{t("renewable-energy")}</span>
          </div>
        </div>
      </div>
      <span className="main-map-title">{t("usage-in-regions")}</span>
      <Map />
      <div id="szechenyi-popup" ref={popupRef}>
        <div
          class="szechenyi-projekt-close"
          onClick={() =>
            popupRef.current.classList.add("szechenyi-popup-hidden")
          }
        ></div>
        <div class="szechenyi-projekt">
          <Link to="/szechenyi-2020">
            <img
              class="szechenyi-projekt-img"
              width={280}
              src="/images/szechenyi-projekt.png"
              alt="szechenyi-2020"
            ></img>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
