import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

export default function Termekek() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="text-4xl mt-32 mb-12 flex justify-center">
        {t("our-products")}
      </div>
      <div className="product-container">
        <div>
          <p className="product-name">{t("akg-low")}</p>
          <p className="product-info">{t("akg-low-output-range")}</p>
        </div>
        <div>
          <p className="product-name">{t("akg-mid")}</p>
          <p className="product-info">{t("akg-mid-output-range")}</p>
        </div>
        <div>
          <p className="product-name">{t("akh-steam")}</p>
          <p className="product-info">{t("akh-steam-output-range-1")}</p>
          <p className="product-info">{t("akh-steam-output-range-2")}</p>
        </div>
        <div>
          <p className="product-name">{t("akh-water")}</p>
          <p className="product-info">{t("akh-water-output-range")}</p>
        </div>
        <div>
          <p className="product-name">{t("vfk")}</p>
          <p className="product-info">{t("vfk-output-range")}</p>
        </div>
        <div>
          <p className="product-name">{t("economisers")}</p>
        </div>
        <div>
          <p className="product-name">{t("waste-heat-utilising")}</p>
        </div>
        <div>
          <p className="product-name">{t("air-tanks")}</p>
          <p className="product-info">{t("air-tanks-output-range")}</p>
        </div>
        <div>
          <p className="product-name">{t("steam-buffers")}</p>
        </div>
        <div>
          <p className="product-name">{t("thermal-degassing")}</p>
        </div>
        <div>
          <p className="product-name">{t("smoke-stacks")}</p>
          <p className="product-info">{t("smoke-stacks-desc")}</p>
        </div>
        <div>
          <p className="product-name">{t("other-equipment")}</p>
          <p className="product-info">{t("other-equipment-desc")}</p>
        </div>
        <div>
          <p className="product-name">{t("custom-boilers")}</p>
          <p className="product-info"> {t("custom-boilers-desc")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
