import "./index.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Rolunk from "./pages/Rolunk";
import Termekek from "./pages/Termekek";
import Vallalkozasok from "./pages/Vallalkozasok";
import Szolgaltatasok from "./pages/Szolgaltatasok";
import Referenciak from "./pages/Referenciak";
import Kapcsolat from "./pages/Kapcsolat";

import ScrollToTop from "./components/ScrollToTop";
import Referencia from "./pages/Referencia";
import Tanusitvanyok from "./pages/Tanusitvanyok";
import Szechenyi2020 from "./pages/Szechenyi2020";
import Gyartas from "./pages/Gyartas";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rolunk" element={<Rolunk />} />
          <Route path="/termekeink" element={<Termekek />} />
          <Route path="/gyartas" element={<Gyartas />} />
          <Route path="/tanusitvanyaink" element={<Tanusitvanyok />} />
          <Route path="/szechenyi-2020" element={<Szechenyi2020 />} />
          <Route path="/vallalkozasok" element={<Vallalkozasok />} />
          <Route path="/szolgaltatasok" element={<Szolgaltatasok />} />
          <Route path="/referenciak" element={<Referenciak />} />
          <Route path="/referencia/:slug" element={<Referencia />} />
          <Route path="/kapcsolat" element={<Kapcsolat />} />

          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
