import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer class="footer-distributed">
      <div class="footer-left">
        <div>
          <h3>
            <Link to={"/"}>Vasfa KFT.</Link>
          </h3>
        </div>
        <div>
          <p class="footer-links">
            <Link to="/rolunk">{t("about-us")}</Link>
            <Link to="/termekeink">{t("products")}</Link>
            <Link to="/tanusitvanyaink">{t("certificates")}</Link>
            <Link to="/gyartas">{t("production")}</Link>
            <Link to="/vallalkozasok">{t("enterprises")}</Link>
            <Link to="/szolgaltatasok">{t("services")}</Link>
            <Link to="/referenciak">{t("references")}</Link>
            <Link to="/kapcsolat">{t("contact")}</Link>
          </p>
        </div>
        <div>
          <p class="footer-company-name">© 2024 Vasfa KFT.</p>
        </div>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span>5000 Szolnok,</span>Nagysándor József út 35.
          </p>
        </div>

        <div>
          <i class="fa fa-phone"></i>
          <p>+36 56 512 010</p>
        </div>

        <div>
          <i class="fa fa-phone"></i>
          <p>+36 30 406 5710</p>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:vasfa@vasfa.hu">vasfa@vasfa.hu</a>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              title="Map"
              class="gmap_iframe"
              width="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=1200&amp;height=400&amp;hl=hu&amp;q=Vasfa Szolnoki Kazángyártó KFt.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://gachanox.io/">Gacha Nox</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
