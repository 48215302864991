import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { certificates } from "../certificates";
import { useTranslation } from "react-i18next";

export default function Tanusitvanyok() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="container mt-24 mx-auto py-10">
        <h1 className="text-3xl font-semibold mb-6 text-center">{t('our-certificates')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12 mx-12">
          {certificates.map((certificate, index) => (
            <div key={index} className="border-green-800 border-2 rounded-md p-4 flex flex-col justify-center text-center">
              <h2 className="text-xl font-semibold">{certificate.name}</h2>
              <p className="text-sm mb-8">{certificate.desc}</p>
              <img
                src={certificate.img[0]}
                alt={certificate.name}
                className="object-center rounded-md mb-4"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}