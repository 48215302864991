import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

export default function Kapcsolat() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div>
        <div className="mapouter2">
          <div className="gmap_canvas2">
            <iframe
              title="map"
              class="gmap_iframe2"
              width="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=1200&amp;height=400&amp;hl=hu&amp;q=Vasfa Szolnoki Kazángyártó KFt.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://gachanox.io/">Gacha Nox</a>
          </div>
        </div>
        <div className="contacts-container">
          <div className="contacts-header">
            <span>
              <strong>{t('contact-us')}</strong>
            </span>
          </div>
          <div className="contacts-main">
            <span>
              <strong className="contacts-title">{t('address')}:</strong> 5000 Szolnok,
              Nagysándor József út 35.
            </span>
            <span>
              <strong className="contacts-title">{t('telephone')}:</strong> +36 56 512
              010
            </span>
            <span>
              <strong className="contacts-title">{t('phone')}:</strong> +36 30 406 5710
            </span>
            <span>
              <strong className="contacts-title">{t('chief-engineer')}:</strong> +36 56 512
              011
            </span>
            <span>
              <strong className="contacts-title">{t('site-manager')}:</strong> +36 56 512
              012
            </span>
            <span>
              <strong className="contacts-title">
              {t('qc-department')}:
              </strong>{" "}
              +36 56 512 013
            </span>
            <span>
              <strong className="contacts-title">
              {t('economization-department')}:
              </strong>{" "}
              +36 56 512 014
            </span>
            <span>
              <strong className="contacts-title">{t('engineering-department')}:</strong> +36
              56 512 017
            </span>
            <span>
              <strong className="contacts-title">
              {t('managing-director')}:
              </strong>{" "}
              +36 56 512 022
            </span>
            <span>
              <strong className="contacts-title">{t('secretariat')}:</strong> +36 56 512
              026
            </span>
            <span>
              <strong className="contacts-title">E-mail:</strong>{" "}
              <a href="mailto:vasfa@vasfa.hu">vasfa@vasfa.hu</a>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
