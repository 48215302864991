import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

export default function Rolunk() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="text-4xl mt-32 flex justify-center">
        {t("about-our-company")}
      </div>
      <div className="px-32 pt-16 text-center">
        <p className="mb-8">
          {t("our-company")}<b>{t("vasfa-ltd")}</b>,{" "}
          {t("about-our-company-content")}
        </p>
        <p>
          {t("company-certificates")}
          <ul className="mb-8">
            <li>
              <b>ISO 9001:2015</b>,
            </li>
            <li>
              <b>MSZ EN ISO 3834-2:2006</b>,
            </li>
            <li>
              <b>2014/68/EU</b>,
            </li>
            <li>
              <b>{t('order-certificate')}</b>,
            </li>
            <li>
              <b>AD 2000 Merkblatt HP 0 / TRD 201</b>,
            </li>
            <li>
              <b>
              {t('certificate-of-production-inspection')}
              </b>
            </li>
          </ul>
          {t('our-company-can-undertake')}
        </p>
        <p className="mt-8">{t('vat-number')}: 11279646-2-16, EU11279646</p>
        <p>{t('szolnok-county')}</p>
      </div>

      <Footer />
    </div>
  );
}
