import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "leaflet/dist/leaflet.css";
import { useParams } from "react-router-dom";
import { companies } from "../companies";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { useTranslation } from "react-i18next";

export default function Referencia() {
  const { t } = useTranslation();

  const { slug } = useParams();
  const company = companies.find((c) => c.reference === slug);

  const [overlay, setOverlay] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);

  if (!company) {
    return <div>Company not found</div>;
  }

  const icon = new Icon({
    iconUrl: "/images/location-pin.png",
    iconSize: [38, 38],
  });

  const slides = company.img;

  const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
      setCurrentIndex(slideIndex);
    };

    return (
      <div className="w-[600px] h-[600px] m-auto py-16 px-4 relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex]})` }}
          className="slide-img cursor-pointer"
          onClick={() => {
            setCurrentIndex(currentIndex);
            setCurrentImg(currentIndex);
            setOverlay(true);
          }}
        ></div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const FullImg = () => {
    const [currentImg, setCurrentImg] = useState(0);

    const handleImgClick = (index) => {
      setCurrentImg(index);
    };

    return (
      <div className={`overlay ${overlay ? "" : "hidden"}`}>
        <div className="thumbnail-container">
          {company.img.map((imgSrc, index) => (
            <div
              key={index}
              className={`thumbnail ${index === currentImg ? "selected" : ""}`}
              style={{ backgroundImage: `url(${imgSrc})` }}
              onClick={() => handleImgClick(index)}
            />
          ))}
        </div>
        <div>
          <img
            src={company.img[currentImg]}
            className={`overlay-image ${overlay ? "" : "hidden"}`}
          />
        </div>
        <div className="close-btn" onClick={() => setOverlay(false)}>
          {t("close")}
        </div>
      </div>
    );
  };

  return (
    <div>
      <FullImg />
      <Header />
      <div className="reference-page-company-name">{company.name}</div>
      <div className="reference-page-company-location">
        <i class="fa-sharp fa-solid fa-location-dot text-sm text-red-500"></i>
        <p>
          {company.city}, {company.year}
        </p>
      </div>
      <div className="reference-page-container">
        <div className="reference-page-left">
          <div className="reference-page-image-container">
            <MapContainer
              className="reference-page-map"
              center={[47.143, 19.424]}
              zoom={7}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={company.geocode} icon={icon}>
                <Popup>{company.name}</Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>

        <div className="reference-page-right">
          <ImageSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
}
