import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header class="header-container">
      <div>
        <Link to="/">
          <img id="logo" src="/images/logo.png" alt="Logo" />
        </Link>
      </div>
      <div>
        <ul>
          <li>
            <Link to="/rolunk">{t('about-us')}</Link>
          </li>
          <li>
            <Link to="/termekeink">{t('products')}</Link>
          </li>
          <li>
            <Link to="/tanusitvanyaink">{t('certificates')}</Link>
          </li>
          <li>
            <Link to="/gyartas">{t('production')}</Link>
          </li>
          <li>
            <Link to="/vallalkozasok">{t('enterprises')}</Link>
          </li>
          <li>
            <Link to="/szolgaltatasok">{t('services')}</Link>
          </li>
          <li>
            <Link to="/referenciak">{t('references')}</Link>
          </li>
          <li>
            <Link to="/kapcsolat">{t('contact')}</Link>
          </li>
        </ul>
      </div>
      <div class="flex gap-2">
        <i
          onClick={() => changeLanguage("hu")}
          class="flag-icon flag-icon-hu text-xl"
        ></i>
        <i
          onClick={() => changeLanguage("en")}
          class="flag-icon flag-icon-gb text-xl"
        ></i>
      </div>
    </header>
  );
}
