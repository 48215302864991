export const certificates = [
    {
        name: "ISO 9001:2015",
        img: ["/images/certificates/ISO 9001 hun.jpg","/images/certificates/ISO 9001 eng.jpg"],
        desc: "Minőségirányítási rendszer"
    },
    {
        name: "MSZ EN ISO 3834-2:2021",
        img: ["/images/certificates/3834.jpg"],
        desc: "Hegesztő üzemalkalmasság"
    },
    {
        name: "2014/68/EU",
        img: ["/images/certificates/2014-68.jpg"],
        desc: "Nyomástartó berendezések gyártására vonatkozó hegesztés technikai feltételeknek való megfelelés"
    },
    {
        name: "8/2018 (VIII. 17. ) ITM rendelet",
        img: ["/images/certificates/8_2018_ITM.jpg"],
        desc: "Az ömlesztőhegesztés végzésének feltételei"
    },
    {
        name: "EK- ÜZEMI GYÁRTÁSELLENŐRZÉS MEGFELELŐSÉGI TANÚSÍTVÁNY (1415-CPR-131-(C-11/2017))",
        img: ["/images/certificates/kémény tanúsítvány.jpg"],
        desc: "VASFA önhordó, acélszerkezetű kémény gyártásáról"
    },
]